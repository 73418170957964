/* You can add global styles to this file, and also import other style files */
@font-face { font-family: 'JustAnotherHandRegular'; font-display: swap; src: url('./assets/fonts/JustAnotherHand-Regular.ttf'); }

ion-app {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Use 100% of the viewport height */
}

app-preview-image {
  --width: 100%;
  --height: 100%;
}

.coloring-modal {
  --width: 100%;
  --height: 100%;
}

ion-router-outlet {
  flex: 1; /* Allows the router outlet to expand and fill available space */
  display: block; /* Ensure it's block-level if not already */
}

ion-content { --overflow: auto; overflow: auto; }

ion-list.main-list { margin-bottom: 6vh; }

.default-zoom-color:hover { background: rgba(233, 233, 206, 0.8) }

.zoom-effect { transition: transform .2s; /* Animation */ }

.zoom-effect:hover {
  transform: scale(1.01); /* Scale the image up to 105% of its original size on hover */
  overflow: hidden;
}

.header-space {display: block; height: 6vh;}

//BREADCRUMBS
.breadcrumbs-container { height: auto; display: block;   padding-left: 10px;   padding-top: 15px;}

.breadcrumbs {
  cursor: pointer;
  small:hover {
    background: rgba(233, 233, 206, 0.8);
  }
  a {
    text-decoration: none;
    color: darkslategray;
  }
}

h1 {
  margin-top: 0 !important;
  font-weight: 700 !important;
  font-size: 1.5rem !important;
  font-family: JustAnotherHandRegular;
  text-transform: uppercase;
  letter-spacing: 0.3ch;
}

h1.name {
  font-size: 1.3rem;
  font-weight: 600;
  // padding: 0.6rem 1rem 0 1rem;
  display: block;
  text-align: center;
  color: black;
}

.h1-container {
  height: 5vh;
  display: block;
  width: 100%;
}

h1.extra-margin-top { margin-top: 1.5vh !important; }

.h1-static-container {
  height: 5vh;
  display: block;
  width: 100%;
}

h2 {
    font-weight: 600;
    font-size: 1.3rem;
}


.h2-container {
  height: 4vh;
  display: block;
  width: 100%;
}

.description {
  color: darkslategray;
  font-weight: 300;
  font-style: italic;
  font-size: 1rem;
  text-align: justify;
  padding-left: 0.5vh;
  padding-right: 0.5vh;
  padding-top: 1.5vh;
}

.item-image-container {
  position: relative;
  aspect-ratio: 1;
  width: 100%;
  height: auto;

  ion-skeleton-text {
    aspect-ratio: 1;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  ion-img.article {
    padding-top: 24%;
  }

  ion-img {
    aspect-ratio: 1;
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    padding: 3px;
  }
}

.item-container {
  text-decoration: none;
  cursor: pointer;
}

.main-image-container {
  position: relative;
  aspect-ratio: 1;
  width: 100%;
  max-height: 62vh;
  padding-bottom: 1vh;
    //ion-skeleton-text { height: 100%; }
    ion-img {
      position: absolute;
      left: 50%;
      top: 0;
      bottom: 0;
      transform: translateX(-50%);
      width: 100%;
    }
    ion-img::part(image) {
      position: absolute;
      left: 50%;
      top: 0;
      bottom: 0;
      transform: translateX(-50%);
      width: auto;
    }
    ion-skeleton-text {
      aspect-ratio: 1;
      max-height: 62vh;
      width: auto;
      margin: 0 auto;
      height: 100%;
    }
}

a { text-decoration: none !important; }

.articles-list-image-container {
  aspect-ratio: 16 / 9;
  width: 100%;
  height: auto;

  ion-skeleton-text {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  ion-img {
    aspect-ratio: 16 / 9;
    width: 98%;
    height: auto;
    position: absolute;
    object-fit: cover !important;
    object-position: inherit;
    display: block;
    top: 0;
    left: 0;
  }
}

.item-name {
  font-size: 1.2rem;
  font-weight: 600;
  padding: 1rem;
  display: block;
  text-align: center;
  font-family: JustAnotherHandRegular;
  text-transform: uppercase;
  letter-spacing: 0.3ch;
  color: darkslategray;
  margin: 0 auto;
}

.hidden { display: none; }

ion-img.fadeIn {
  position: relative;
  //animation: fadeInAnimation 0.1s ease-in-out;
  //animation-fill-mode: forwards;
}

// related Items and Categories
.related-item-image-container {
  position: relative;
  aspect-ratio: 1;
  width: 100%;
  display: inline-flex;
  height: auto;
  ion-skeleton-text {
    height: 100%;
  }
  ion-img.article {
    padding-top: 24%;
  }

  ion-img {
    position:absolute;
    aspect-ratio: 1;
    height: 100%;
    width: 100%;
  }
  ion-img::part(image) {
    background: black;
  }
}

.related-item-name {
  font-size: 0.9rem;
  font-weight: 500;
  padding: 0 1rem;
  display: block;
  text-align: center;
  font-family: JustAnotherHandRegular;
  text-transform: uppercase;
  letter-spacing: 0.3ch;
  color: darkslategray;
  margin: 0 auto;
}

.related-items-heading, .related-categories-heading {
  margin: 1rem;
  font-family: JustAnotherHandRegular;
  display: block;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 0.3ch;
  color: gray;
  font-size: 1.2rem;
  font-weight: 700;
  padding: 1rem 0rem 1rem 3rem;
}


@keyframes fadeInAnimation {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

.ads { display: none; }

@media only screen  and (min-width : 600px) {
  ion-router-outlet { margin-top: 6vh; }

  .header-space {display: block; height: 8vh;}

  .breadcrumbs-container { padding-top: 5px;}


  .ads { display: block }

  ion-list.main-list { margin-top: 4vh; }

  .category-name { padding: 0 1rem; }

  .h1.extra-margin-top { margin-top: 0; }
}

@media only screen  and (min-width : 1200px) {

  .ads { display: block }

  .main-column { padding: 0 6rem}

  ion-list.main-list {
    margin-bottom: 0;
    margin-top: 5vh;
  }
}